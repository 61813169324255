import moment from 'moment';
import draftToHtml from "draftjs-to-html";

export const setTitle = (title) => {
   if (title) {
      document.title = title + ' - ' + '';
   }
};
export const isNumber = (a)=>{
   return isNaN(Number(a)) ? false : typeof Number(a) === 'number'
}
export const filterObject = (a = {}) => {
   const arr = {...a};
   Object.keys(a).forEach(key => {
      if (arr?.[key]) {
         if (!arr[key]) {
            delete arr[key]
         } else if (arr[key].toString().length === 0) {
            delete arr[key]
         }
      }
   })
   return arr
}
export const arrayRemoveIndex = (arr, ind) => arr.filter((_, index) => index !== ind);
export const arrayRemove = (arr, key, val) => arr.filter(a => a[key] !== val);

export const checkValueExists = (a={})=> Object.entries(a).some(([k,v])=>!!v)

export const reload = () => window.location.reload();

export const parseFloatNaN = a => isNaN(a) || a === '' ? 0 : parseFloat(a);

export const spaceReduce = (a) => a ? a.split(' ').join('_') : '';
export const spaceReduceSmall = (a='') => a ? a.trim().replace(/[\'\?\_\-\$\#\!\&\+\<\>\.\"\,\:\;\{\}\[\]\%\^\*\(\)\@\|]/gm,'').split(/\s+|\/|\\/g).join('-').toLowerCase() : '';
export const spaceReverse = (a) => a ? a.split('_').join(' ') : '';

export const objectFind = (a, key, select) => {
   const res = a.find(i => i[key] === select);
   if (res) {
      return res;
   }
   return {};
};

export const objectGetValue = (a, key, select, value) => {
   const res = a.find(i => i[key] === select);
   if (res) {
      return res?.[value] ?? null;
   }
   return null;
};

export const objectFindCheck = (a, key, select) => a.findIndex(i => i[key] === select) > -1;

export const objectFindUpdate = (a = null, key, select, res) => {
   if (!a) {
      return null;
   }
   let arr = [...a];
   let ind = arr.findIndex(i => i[key] === select);
   arr[ind] = { ...arr[ind], ...res };
   return [...arr];
};

export const toFixedConvert = (a = "0") => {
   return Number(a).toFixed(2);
}

export const arrayUpsert = (data = [], key, value, obj) => {
   let newData = data;
   if (objectFindCheck(data, key, value)) {
      newData = objectFindUpdate(data, key, value, obj);
   } else {
      newData.unshift(obj);
   }
   return newData;
};

export const arrayConvertAccordion = (arr=[],title,content) => arr.map(i=> ({title:i[title],content:i[content]}))
export const lookupArray = array => Object.assign({}, ...array.map(i => ({ [i.value]: i.text })));
export const arrayMapKeyValue = (arr,key,value) => arr.map(i => ({[key]:i[value]}));
export const optionArray = (array, val, text) => array.map(i => ({ value: i[val], text: i[text],key:i[val], ...i }));
export const optionAutoComplete = (array, label) => array.map(i => ({label:i[label] ?? null})).filter(i => i.label)
export const arrayAutoComplete = (array) => array.map(i => ({label:i}));

export const arrayToOption = (arr = []) => arr.flatMap((val, ind) => (val ? { text: val, value: ind } : []));

export const timeRender = (a = null) => a ? moment(a).format('DD-MMM-YYYY hh:mm A') : null;
export const timeRenderBlog = (a = null) => a ? moment(a).format('DD MMMM, YYYY') : null;

export const capitalize = a => a ? a.toString().split('')[0].toUpperCase() + a.slice(1) : '';

export const slugCreate = a=> a.toLowerCase().split(' ').join('-')

export const milliToAge = (a) => Math.round(moment.duration(a).asYears());
export const dateRender = (a) => moment(a).format('DD-MM-YYYY');

export const milliToYear = (a) => moment(a).format('YYYY');
export const createdTime = (a) => moment(a).format('MMM DD, YYYY ');
export const milliToDate = (a) => moment(a).format('YYYY-MM-DD');
export const updateToArrayLoop = (a = [], obj) => a.map(i => Object.assign(i, obj));
export const freezeClone = (a = {}) => JSON.parse(JSON.stringify(a));
export const sum = (a, key) => (a.reduce((acc, b) => {
   if (b[key]) {
      acc = acc + parseFloatNaN(b[key]);
   }
   return acc;
}, 0)).toFixed(2);

export const href = link => {
   window.open(link);
};
export const openNewWindow = link => {
   window.open(link,'_blank');
};
export const hashOpen = (link = null) => {
   window.open(`#${link}`);
};

export const scrollToBottom = (elem) => {
   if (elem?.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
   }
};

export const duplicateRemove = (arr, key) => {
   return arr.map(a => a[key]).filter((a, ind, ar) => ind === ar.indexOf(a)).reduce((acc, curr) => (acc.push({ [key]: curr }), acc), []);
};

export const NumGen = (length) => Array.from(Array(length), (_, ind) => ind);

export const timeRandomName = (a = 'upload') => {
   const suf = moment().format('DD_MM_YYYY_HH_mm_SS') + Math.round(Math.random() * 100000000).toString();
   return a + '_' + suf;
};

export const strReduce =(str,length=30)=>{
   return str.substr(0,length)
}

export const slugUrl = (value='')=>value.trim().toLowerCase().match(/\w+/igm).join('-')
export const removeSlash = (value='') => value.split('/').join('');
export const copyText=(text)=>{
   navigator.clipboard.writeText(text);
}

export const convertToHTML=(data)=> draftToHtml(data).toString()

export  const parseHTML = (html)=> {
  const str = new DOMParser().parseFromString(html, "text/html");
  return str.body.textContent
}
export const sliceString=(data)=> {
   if(data.length > 100){
     return  <span>{data.slice(0,250)} <span style={{color: 'blue'}}>see more...</span></span>
   }
   return data
 }