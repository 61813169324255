import draftToHtml from "draftjs-to-html"
import { spaceReduceSmall } from "../../helpers/jsHelper"
import { IMAGE_API, S3_BUCKET_URL } from "../../config/constant/projectConstant"
import moment from "moment"
import { USER_IMG } from "../../config/constant/imgConstant"

export const TextEditorHTML = ({ data=null })=>{
    const htmlData =  data ? draftToHtml(data).toString() : null
    return htmlData&& <div dangerouslySetInnerHTML={{ __html: htmlData }}/>
}


export const titleFixer = (a='')=>{
    return a.replace(/\|(.*)VirtualStaff\.ph/gmi,'')
}

export const profileUrlCreate = (a) => {
    if (!a?._id) {
        return null
    }
    const title = spaceReduceSmall(a?.job_title || '');
    const name = spaceReduceSmall(a?.user_full_nam || '');
    const second = title ? title : name
    let url = [a._id, second].join('/');
    url = url.replace(/--/g, '')
    return url
}


export const ImageLoader = (val = null) => {
    const unless = ["jobseeker"];
    if (!val) {
        return USER_IMG
    } else {
        const result = unless.some((a) => {
            return new RegExp(`${a}`, "gi").test(val);
        });
        if (result) {
            return `${S3_BUCKET_URL}${val}`
        } else {
            return `${IMAGE_API}${val}`
        }
    }
    return ''

}
export const htmlDecode = (input) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}
export const imgBannerCheckOLD = (img = '') => img.indexOf('blog_img') > -1 ? S3_BUCKET_URL + img : ImageLoader(img)

export const HtmlToString = ({ input = null }) => {
    let data = <div dangerouslySetInnerHTML={{ __html: htmlDecode(input) }} />
    let check = data?.props?.dangerouslySetInnerHTML?.__html
    return check !== null ? <div dangerouslySetInnerHTML={{ __html: htmlDecode(input) }} /> : <div dangerouslySetInnerHTML={{ __html: input }} />
}


export const timeInCalc = () => {
    let res = ['00:00'];
    let startTime = moment('00:00', 'HH:mm');
    for (let i = 0; i < 12;) {
        let d = startTime.add(15, 'minutes');
        i = d.format('HH');
        res.push(d.format('HH:mm'));
    }
    return res;
};
