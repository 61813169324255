export const LOGO_IMG = '/assets/images/site-admin/logo.png';
export const DUMMY_POST = '/assets/images/site-admin/dummy_post.jpg';
export const PLACEHOLDER = '/assets/images/loading.png';
export const FOOTER_IMG = '/assets/images/homepage/footer.jpg'
export const USER_IMG = "/assets/images/user_img.png"
export const LONG_IMG = "/assets/images/download.jpeg"
export const PEN_IMG = "/assets/images/feather-pen.png"
export const BANNER_IMG = "/assets/images/blog/innerblogbanner.jpg"
export const SALARY_BANNER = "/assets/images/salary-guide.jpg"
export const SALARY_CALCULATOR = "/assets/images/salary-calculator.jpg"
export const OUTSOURCING_BANNER = "/assets/images/outsourcing-banner.jpg"
export const OUTSOURCING_PLAYBOOK_BANNER = "/assets/images/outsourcing_playbook.jpg"
export const DYNAMIC_SLIDER_IMAGE = "/assets/images/dynamic-slider-constant.png"