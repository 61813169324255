import { SALARY_BANNER , SALARY_CALCULATOR ,OUTSOURCING_BANNER, OUTSOURCING_PLAYBOOK_BANNER} from "./imgConstant";

export const NETWORK_ERROR = 'Network Error';
export const SITE_ADMIN_TITLE = 'VS-Site Admin';
export const USER_DATA = 'userData';
export const TOASTR_OPTIONS = {
    timeOut: 3000,
    closeOnToastrClick: true,
};
export const THEME_DARK_BLUE = '#004c70'
export const PREVIEW_COOKIE = '__next_preview_data'
export const COOKIE_NAME = 'virtual_staff'
export const DEFAULT_TOKEN = 'token';
export const ACCESS_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoibG9uZyBleHBpcmUgdG9rZW4iLCJfaWQiOiI2MTQ5ODMyNGUyNGVlODdjMjA3Y2I4YTIiLCJpYXQiOjE2MzIyMDc2NTIsImV4cCI6Mjg4MDE2MzIyMDc2NTJ9.oxzWGKgYxfZ1RW77pKFqZD0X_gOfe-Q2rV_AoHtCekE';
export const JOB_TYPE = [
    {value: 'full_time', text: 'Full Time'},
    {value: 'part_time', text: 'Part Time'}
];

export const JOB_TYPE_OBJ = {
    full_time: 'Full Time',
    part_time: 'Part Time'
};


//backend
export const SESSION_NAME = 'userData'

export const POST_SUGGEST_TYPE = [
    {value:'job_seeker',text:'Job Seeker Only'},
    {value:'employer',text:'Employer Only'},
    {value:'general',text:'General'}
]

export const POST_MODE_ENUM = ['draft','scheduled','published']
export const VERIFICATION_TYPE = [
    { value: 'All', text: 'All' },
    { value: 'verified', text: 'Verified' },
    // { value: 'unverified', text: 'Unverified' },
    
 ]
 
export const SETTING_ENUM = {
    SETTING_INJECTOR: 'injector'
}
//post type
export const POST_ENUM = {
    POST_JOB_TYPE: 'job',
    POST_HELP_ARTICLE_TYPE: 'help_article',
    POST_STATIC_PAGE_TYPE: 'static',
    POST_BLOG_TYPE: 'blog',
    POST_BLOG_POST: 'blog_post',
    POST_BLOG_CATEGORY: 'blog_category',
    POST_RESUME_TYPE: 'resume',
    POST_PAGE_TYPE: 'page'
}


//title_type
export const TITLE_ENUM = {
    TITLE_BLOG_CATEGORY_TYPE: 'blog-category',
    TITLE_BLOG_TAG_TYPE: 'blog-tag',
    TITLE_HELP_CATEGORY_TYPE: 'help-category',
    TITLE_HELP_TOPIC_TYPE: 'help-topic',
    TITLE_BLOG_TYPE: 'blog-type',
}

export const SITE_NAME = 'VirtualStaff.ph'
export const SITE_URL = 'https://virtualstaff.ph'
export const ROOT_DOMAIN = process.env.ROOT_DOMAIN
export const FAVICON_IMG = 'https://www.virtualstaff.ph/favicon.png'


export const SOCIAL_MEDIA_LIST = [
    {icon:'facebook',platform:'facebook'},
    {icon:'twitter',platform:'twitter'},
    {icon:'linkedin',platform:'linkedin'},
    {icon:'youtube',platform:'youtube'},
    {icon:'instagram',platform:'instagram'},
]

export const HELPFULL_RESOURCE = [
    {image:SALARY_BANNER,title:"Learn How to Cut Employee Costs By 82%... Without Compromising Quality!",link:"salary-guide", route: true},
    {image:SALARY_CALCULATOR,title:"Find Out How Much You Can Save By Hiring From the Philippines",link:"virtual-staff-calculator", route: false},
    {image:OUTSOURCING_BANNER,title:"Transform Your Company with Top Talent, Low Costs, and Incredible Efficiency!",link:"outsourcing-guide", route: true},
    {image:OUTSOURCING_PLAYBOOK_BANNER,title:"The Outsourcing Cheat Sheet: Tapping into the Philippines",link:"outsourcing-playbook", route: true}
]

export const TALENT_CATEGORIES = ['work-from-home-tips']
export const VALID_CATEGORIES = ['practical-tips','managing','podcast','newsletter','hiring']

export const POD_COST_LIST = [
  { name: 'Podbean',field:'podbean', image: '/assets/images/podcast/podbean.png'},
  { name: 'Apple Podcast',field:'apple', image: '/assets/images/podcast/apple.png'},
  { name: 'Spotify',field:'spotify', image: '/assets/images/podcast/spotify.png'},
]

export const S3_BUCKET_URL = process.env.NEW_S3_BUCKET_URL

export const IMAGE_API = process.env.IMAGE_API_ROOT
export const OLD_S3 = process.env.OLD_S3_BUCKET_URL


export const SALARY_ROLES = [
    {
      "role": "Alibaba Virtual Assistant",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Chat Support Specialist",
      "entry_level": "$2.00 - $3.50/hr",
      "intermediate": "$3.50 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "Affiliate Marketing Manager",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "Facebook Ads Manager",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "Project Managers",
      "entry_level": "Not advisable",
      "intermediate": "$3.75 - $7.50/hr",
      "senior": "$7.50+/hr"
    },
    {
      "role": "Sales Reps",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $5.50/hr",
      "senior": "$5.50+/hr"
    },
    {
      "role": "Researcher",
      "entry_level": "$2.00 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Lead Generation Specialist",
      "entry_level": "$2.00 - $3.75/hr",
      "intermediate": "$3.75 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "PPC Manager",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $8.00/hr",
      "senior": "$8.00+/hr"
    },
    {
      "role": "Bookkeeping Virtual Assistant",
      "entry_level": "$2.00 - $4.00/hr",
      "intermediate": "$4.00 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Admin Support",
      "entry_level": "$2.00 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Cold Callers",
      "entry_level": "$2.00 - $3.00/hr",
      "intermediate": "$3.00 - $5.00/hr",
      "senior": "$5.00+/hr"
    },
    {
      "role": "Podcast Editor",
      "entry_level": "Not advisable",
      "intermediate": "$3.00 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Customer Service Representative",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $5.00/hr",
      "senior": "$5.00+/hr"
    },
    {
      "role": "Operations Manager",
      "entry_level": "Not advisable",
      "intermediate": "$4.00 - $10.00/hr",
      "senior": "$10.00+/hr"
    },
    {
      "role": "Virtual Executive Assistant",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Paralegal",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $8.00/hr",
      "senior": "$8.00+/hr"
    },
    {
      "role": "Programmer",
      "entry_level": "$2.50 - $5.00/hr",
      "intermediate": "$5.00 - $10.00/hr",
      "senior": "$10.00+/hr"
    },
    {
      "role": "Bookkeeper",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $7.50/hr",
      "senior": "$7.50+/hr"
    },
    {
      "role": "Personal Assistant",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "WordPress Developer",
      "entry_level": "$2.50 - $4.50/hr",
      "intermediate": "$4.50 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "FBA Virtual Assistant",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Social Media Manager",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $7.00/hr",
      "senior": "$7.00+/hr"
    },
    {
      "role": "SEO Expert",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "Digital Marketer",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "Copywriter",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $7.50/hr",
      "senior": "$7.50+/hr"
    },
    {
      "role": "Technical Support Representative",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Amazon Virtual Assistant",
      "entry_level": "$2.50 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Accountant",
      "entry_level": "$3.00 - $5.00/hr",
      "intermediate": "$5.00 - $8.00/hr",
      "senior": "$8.00+/hr"
    },
    {
      "role": "Web Developer",
      "entry_level": "$2.50 - $5.00/hr",
      "intermediate": "$5.00 - $10.00/hr",
      "senior": "$10.00+/hr"
    },
    {
      "role": "Video Editor",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $7.00/hr",
      "senior": "$7.00+/hr"
    },
    {
      "role": "Billing Specialist",
      "entry_level": "$2.50 - $3.75/hr",
      "intermediate": "$3.75 - $5.75/hr",
      "senior": "$5.75+/hr"
    },
    {
      "role": "Animators",
      "entry_level": "$3.00 - $5.00/hr",
      "intermediate": "$5.00 - $10.00/hr",
      "senior": "$10.00+/hr"
    },
    {
      "role": "Graphic Designer",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $7.00/hr",
      "senior": "$7.00+/hr"
    },
    {
      "role": "Google Ads Manager",
      "entry_level": "$2.50 - $4.00/hr",
      "intermediate": "$4.00 - $8.00/hr",
      "senior": "$8.00+/hr"
    },
    {
      "role": "Content Writer",
      "entry_level": "$2.00 - $3.50/hr",
      "intermediate": "$3.50 - $6.50/hr",
      "senior": "$6.50+/hr"
    },
    {
      "role": "Telemarketer",
      "entry_level": "$2.00 - $3.00/hr",
      "intermediate": "$3.00 - $5.00/hr",
      "senior": "$5.00+/hr"
    },
    {
      "role": "Virtual Assistant",
      "entry_level": "$2.00 - $3.50/hr",
      "intermediate": "$3.50 - $6.00/hr",
      "senior": "$6.00+/hr"
    },
    {
      "role": "Real Estate Virtual Assistant",
      "entry_level": "$2.00 - $3.75/hr",
      "intermediate": "$3.75 - $5.75/hr",
      "senior": "$5.75+/hr"
    }
  ]


